import React, {Component} from 'react';
import './caltoaction.scss';

class CallToActionSection extends Component {
    render (){
        return (
            <div className="call-to-action text-center">
                <div className="container">
                    {/* <h2 className="text-uppercase text-white">LANDPARK</h2> */}
                    <h1 className="text-uppercase text-white mb-4"> Contacto</h1>
                    <div className="btn-call-to-action">
                        
                    </div>
                </div>
            </div>
        )
    }
}

export default CallToActionSection